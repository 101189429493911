import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"

const GDPRPage = () => (
  <Layout>
    <SEO title="General Data Protection Regulation" />
    <Hero title={'General Data Protection Regulation'} />
    <Content>
      <div style={{width: '90%', maxWidth: '1080px', margin: 'auto'}} >
      <h1>Points’ Approach to GDPR</h1>
      <p style={{textAlign: 'left'}}>
        Data protection and security have always been at the core of our
        business at Points.com Inc.(Points) We are dedicated to security and
        privacy by adopting industry best practices to protect your data. As a
        part of this, we have been working to ensure our compliance with the EU
        General Data Protection Regulation (GDPR).
      </p>
      <h3>What is the GDPR?</h3>
      <p style={{textAlign: 'left'}}>
        The GDPR is a new European Union (EU) law that applies to all residents
        of the European Economic Area (EEA), and comes into effect on May 25th,
        2018. GDPR is designed to ensure all EEA residents have greater control
        over how their personal data is processed, stored, used, and disposed.
        The GDPR applies to any company that handles the personal data of
        residents in the EEA.
      </p>
      <h3>What has Points done to prepare for the GDPR?</h3>
      <p style={{textAlign: 'left'}}>Points has:</p>
      <ul>
        <li>
          Appointed a Data Protection Officer (
          <a href="mailto:dpo@points.com?subject=Request from Points.com -">
            DPO@points.com
          </a>
          ).
        </li>
        <li>
          Raised internal awareness, and delivered training to key internal
          teams.
        </li>
        <li>Formed a core GDPR team with members from key internal groups.</li>
        <li>
          Updated Points’ Privacy Policy and other policies to address GDPR
          requirements.
        </li>
        <li>
          Implemented processes related to individuals exercising their rights
          under the GDPR.
        </li>
        <li>
          Created a data protection impact assessment process, integrated with
          Points’ existing processes, such that data protection is considered at
          multiple phases in a development project.
        </li>
        <li>
          Implemented processes that support our GDPR compliance efforts
          generally.
        </li>
        <li>
          Engaged a third-party information security auditor to help identify
          any gaps or areas of weakness during our GDPR implementation.
        </li>
        <li>
          Engaged with key suppliers to ensure that data processing is aligned
          with GDPR requirements.
        </li>
      </ul>
      <p>
        Points has developed, and will continue to develop and improve upon, our
        GDPR compliance program. This will help us to manage any necessary and
        appropriate changes that may be required as GDPR best practices develop
        and evolve.
      </p>
      <h3>Learn More About Points &amp; GDPR</h3>
      <p style={{textAlign: 'left'}}>
        For more detailed information about Points, GDPR and your data, please
        refer to our <Link to="/legal/terms/">Terms of Use</Link> and{" "}
        <Link to="/legal/privacy/">Privacy Policy</Link> online at:
      </p>
      <ul>
        <li>
          Points.com Inc. <Link to="/legal/terms/">Terms of Use</Link>
        </li>
        <li>
          Points.com Inc. <Link to="/legal/privacy/">Privacy Policy</Link>
        </li>
        <li>
          Points.com Inc.{" "}
          <Link
            to="https://www.points.com/pdfs/Subprocessor_List.pdf"
            
            rel={'noreferrer'}
          >
            Subprocessor List
          </Link>
        </li>
      </ul>

      <h3>Still have questions?</h3>
      <p style={{textAlign: 'left'}}>
        We’re happy to help! Please contact{" "}
        <a href="mailto:dpo@points.com?subject=Request from Points.com -">
          DPO@points.com
        </a>
      </p>
      </div>
    </Content>
  </Layout>
);

export default GDPRPage;
